import React from "react"

import { Link } from "gatsby"

const HeaderLink = (props) => {
  const { text, link, children } = props;
  const fullLink = `/${link}`;
  return <Link
    to={fullLink}
  >
    {text || children}
  </Link>
}

export default HeaderLink